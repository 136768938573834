import { Component } from '@angular/core';
import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PatientSearchModalComponent } from 'src/app/pages/patient-search-modal/patient-search-modal.component';
import { debounce } from 'lodash';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent {
  menuItems: MenuItem[] = [];
  showAppsPanel: boolean;
  expandSidebar: boolean = false;

  constructor(private readonly _router: Router,
    private readonly _modalService: BsModalService,
    private readonly _authService: AuthenticationService
  ) {
    this.menuItems = MENU;
  }

  menuItemClicked(menuItem: MenuItem) {
    if (!menuItem.hasExternalLink) {
      return;
    }
    const linkWithToken = this._authService.appendEncryptedAuthInfo(menuItem.link);
    window.open(linkWithToken, '_blank');
  }

  openSettingsApp() {
    const linkWithToken = this._authService.appendEncryptedAuthInfo(environment.adminAppUrl);
    window.open(linkWithToken, '_blank');
  }

  isCurrentRoute(route: string): boolean {
    return this._router.url === route;
  }

  showAllTools(evt: Event) {
    this.showAppsPanel = true;
    this.expandSidebar = false;
    evt.stopPropagation();
  }

  openSeachModal() {
    const options: ModalOptions = {
      class: 'patient-search-modal modal-lg',
      ignoreBackdropClick: true
    };
    this._modalService.show(PatientSearchModalComponent, options);
  }

  expandSidebarWithDebouce = debounce((flag) => this.expandSidebar = flag, 300);
}