import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { PagetitleComponent } from './components/pagetitle/pagetitle.component';
import { ToastrModule } from 'ngx-toastr';
import { SafeUrlPipe } from '../core/pipes/safe-url.pipe';
import { SsnDirective } from './directives/ssn.directive';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { PhonePipe } from '../core/pipes/phone.pipe';
import { DateWithoutTimezonePipe } from '../core/pipes/date-without-timezone.pipe';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { ReplaceUnderscoreWithSpacePipe } from '../core/pipes/replace-underscore-with-space.pipe';
import { YesNoPipe } from '../core/pipes/yes-no.pipe';
import { SplitCamelCasePipe } from '../core/pipes/split-camel-case.pipe';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout';

@NgModule({
  declarations: [
    LoaderComponent,
    PagetitleComponent,
    UploadImageComponent,
    SafeUrlPipe,
    SsnDirective,
    PhonePipe,
    DateWithoutTimezonePipe,
    ScrollSpyDirective,
    YesNoPipe,
    AutofocusDirective,
    ReplaceUnderscoreWithSpacePipe,
    SplitCamelCasePipe,
    SessionTimeoutComponent
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    ToastrModule.forRoot({ timeOut: 3000 }),
  ],
  exports: [
    LoaderComponent,
    PagetitleComponent,
    SafeUrlPipe,
    SsnDirective,
    UploadImageComponent,
    PhonePipe,
    YesNoPipe,
    DateWithoutTimezonePipe,
    ScrollSpyDirective,
    AutofocusDirective,
    ReplaceUnderscoreWithSpacePipe,
    SplitCamelCasePipe,
    SessionTimeoutComponent
  ]
})

export class SharedModule { }
