import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PatientImage } from 'src/app/core/models/patient.model';

@Component({
  selector: 'upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit, OnDestroy {
  @Input() customClass: string;

  @Output() onPictureChanged = new EventEmitter<PatientImage>();

  public readonly uploadedFile: BehaviorSubject<string> = new BehaviorSubject(null);

  private subscription: Subscription;

  public readonly control = new FileUploadControl(
    { listVisible: true, accept: ['image/*'], discardInvalid: true, multiple: false },
    [FileUploadValidators.accept(['image/*']), FileUploadValidators.filesLimit(1)]
  );

  public ngOnInit(): void {
    this.subscription = this.control.valueChanges.subscribe((values: Array<File>) => this.getImage(values[0]));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getImage(file: File): void {
    if (FileReader && file) {
      const fr = new FileReader();
      fr.onload = (e) => {
        const base64Splitted = (e.target as any).result.split('base64,');
        const base64Data = base64Splitted[1];
        const imageType = base64Splitted[0].replace(';', '').replace('data:', '');
        this.onPictureChanged.emit({
          base64String: base64Data,
          contentType: imageType
        })
        this.uploadedFile.next((e.target as any).result);
      }
      fr.readAsDataURL(file);
    } else {
      this.onPictureChanged.emit(undefined)
      this.uploadedFile.next(undefined);
    }
  }
}
