import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Table } from 'primeng/table';
import { Observable, Subject, catchError, concat, debounceTime, distinctUntilChanged, of, switchMap, tap } from 'rxjs';
import { EnumerationItem } from 'src/app/core/models/lookup.model';
import { PatientSearchResult } from 'src/app/core/models/patient.model';
import { EventService } from 'src/app/core/services/event.service';
import { PatientService } from 'src/app/core/services/patient.service';

@Component({
  selector: 'app-patient-search-modal',
  templateUrl: './patient-search-modal.component.html',
  styleUrls: ['./patient-search-modal.component.scss']
})
export class PatientSearchModalComponent implements OnInit, AfterViewInit {
  @ViewChild('searchField') searchField: ElementRef;
  @ViewChild('patientSearchTable') patientSearchTable: Table | undefined;
  patientsList$: Observable<PatientSearchResult[]>;
  loadingPatients = false;
  patientsInput$ = new Subject<string>();

  constructor(public bsModalRef: BsModalRef,
    private readonly _patientService: PatientService,
    private readonly _router: Router,
    private readonly _eventService: EventService
  ) {
  }

  trackByFn(item: EnumerationItem) {
    return item.value;
  }

  ngOnInit(): void {
    this.searchPatients();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchField.nativeElement.focus();
    }, 50);
  }

  private searchPatients() {
    this.patientsList$ = concat(
      of(null),
      this.patientsInput$.pipe(
        distinctUntilChanged(),
        debounceTime(400),
        tap(() => {
          this.loadingPatients = true;
          if (this.patientSearchTable?.first) {
            this.patientSearchTable.first = 0;
          }
        }),
        switchMap(term => this._patientService.searchPatientByPartialName(term)
          .pipe(
            catchError(() => of(null)),
            tap(() => this.loadingPatients = false)
          ))
      )
    );
  }

  openPatientDetails(patientId: string) {
    this._eventService.broadcast('showPatientDetails', patientId);
    this._router.navigate([`/patients/${patientId}`]);
    this.bsModalRef.hide();
  }

  onUserInput(event: Event) {
    const userInput: string = (event.target as HTMLInputElement).value;
    if (userInput?.length < 3) {
      this.patientsInput$.next(null);
      return;
    }
    this.patientsInput$.next(userInput);
  }

  hasSearchTerm(searchField: HTMLInputElement): boolean {
    return !!searchField.value;
  }
}