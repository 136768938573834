import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {

  @Input() breadcrumbItems;
  @Input() title: string;
  @Input() showBackButton: boolean = false;
  @Output() onBackButtonClick = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  back(){
    this.onBackButtonClick.emit();
  }

}
