import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { ApplicationContextService } from '../services/application-context.service';
import { map } from 'rxjs';
import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private readonly _authService: AuthenticationService,
        private readonly _applicationContextService: ApplicationContextService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this._applicationContextService.context.pipe(map(context => {
            if (!context.currentlyLoggedInUser) {
                this._authService.logout();
                return false;
            }
            return true;
        }))
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        return this.canActivate(next, state);
    }
}
