import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ApplicationContextService } from 'src/app/core/services/application-context.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { AvailableApplication } from 'src/app/core/models/environment.interface';

@Component({
  selector: 'app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss']
})
export class AppListComponent implements OnInit {
  @Output() appsPanelClosed: EventEmitter<void> = new EventEmitter<void>();
  isGlobalAdmin: boolean;
  availableLicenses: string[];

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    if (targetElement.id !== 'apps-list' && targetElement.parentElement.id !== 'apps-list') {
      this.appsPanelClosed.emit()
    }
  }

  ngOnInit(): void {
    this._appContextService.context.subscribe(context => {
      this.isGlobalAdmin = context.currentlyLoggedInUser.globalAdmin
      this.availableLicenses = context.companyLicenses;
    });
  }

  constructor(private readonly _appContextService: ApplicationContextService,
    private readonly _authService: AuthenticationService
  ) {
  }

  openUrl(url: string): void {
    const linkWithToken = this._authService.appendEncryptedAuthInfo(url);
    window.open(linkWithToken, '_blank');
  }

  appClicked(app: string, hasLicense: boolean) {
    if (!hasLicense) {
      return;
    }
    switch (app) {
      case AvailableApplication.Charts:
        this.openUrl(environment.chartsAppUrl);
        return;
      case AvailableApplication.Remit:
        this.openUrl(environment.remitAppUrl)
        return;
      case AvailableApplication.Admin:
        this.openUrl(environment.adminAppUrl)
        return;
      case AvailableApplication.Biller:
        this.openUrl(environment.billerAppUrl)
        return;
      case AvailableApplication.Studies:
        this.openUrl(environment.studiesAppUrl)
        return;
      case AvailableApplication.Phi:
        this.openUrl(environment.phiAppUrl)
        return;
      case AvailableApplication.Vascunote:
        window.open('https://vapps.vmamd.com/', '_blank');
    }
  }
}
