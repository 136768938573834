import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PatientStudyService } from 'src/app/core/services/patient-study.service';

@Component({
  selector: 'app-study-details',
  templateUrl: './study-details.component.html',
  styleUrls: ['./study-details.component.scss']
})
export class StudyDetailsComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  private _subscriptions: Subscription;

  constructor(private readonly _toastService: ToastrService,
    private readonly _studiesService: PatientStudyService,
    private readonly _route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    if (!this._route.snapshot.queryParams.ids) {
      return;
    }
    const request: string[] = this._route.snapshot.queryParams.ids.split(',');
    if (!request?.length) {
      return;
    }
    this._subscriptions = this._studiesService.getFileByStudyUrl(request)
      .subscribe({
        next: url => {
          if (!url) {
            this._toastService.error('teleray url does not appear to be valid.', 'Error!');
            return;
          }
          window.location.href = url;
        },
        error: err => {
          console.log(err);
          this._toastService.error(err || 'Unable to get studies url due to unknown reason.', 'Error!')
        }
      });
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }
}
