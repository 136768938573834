import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { InterpretationRequest } from '../models/patient-study.model';

@Injectable({
  providedIn: 'root'
})
export class PatientStudyService {

  constructor(private readonly _dataService: DataService) { }

  getPatientStudies(patientId: string) {
    let url = `${this.apiBaseUrl}api/v1/studies/patient/${patientId}`;
    return this._dataService.get(url);
  }

  refreshStudies(patientId: string) {
    let url = this.apiBaseUrl + 'api/v1/studies/sync-studies';
    if (patientId) {
      url += `?patientId=${patientId}`;
    }
    return this._dataService.post(url, null);
  }

  saveInterpretation(request: InterpretationRequest) {
    const url = this.apiBaseUrl + `api/v1/studies/interpretation`;
    return this._dataService.post(url, request)
  }

  getFileByStudyUrl(ids: string[]): Observable<string> {
    let url = this.apiBaseUrl + `api/v1/studies/patient/study-view`;
    return this._dataService.post(url, ids).pipe(
      map(response => response.url)
    )
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }
}
