import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ExtendedPatient, Patient, PatientImage, PatientSearchResult } from '../models/patient.model';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DateWithoutTimezonePipe } from '../pipes/date-without-timezone.pipe';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private readonly _dataService: DataService) { }

  getCompanyPatients(): Observable<Patient[]> {
    const url = this.apiBaseUrl + 'api/v1/patients'
    return this._dataService.get(url);
  }

  getPatientById(patientId: string): Observable<ExtendedPatient> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`
    return this._dataService.get(url);
  }

  insertPatient(patient: Patient): Observable<Patient> {
    const url = this.apiBaseUrl + 'api/v1/patients'
    return this._dataService.post(url, patient);
  }

  updatPatient(patientId: string, patient: Patient): Observable<Patient> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`
    return this._dataService.patch(url, patient);
  }

  deletePatient(patientId: string): Observable<void> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`
    return this._dataService.delete(url);
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  getPatientImage(patientImage: PatientImage) {
    if (!patientImage) {
      return '/assets/images/custom/patient-avatar.png';
    }
    return `data:${patientImage.contentType};base64, ${patientImage.base64String}`;
  }

  searchPatientByPartialName(searchTerm: string): Observable<PatientSearchResult[]> {
    if (!searchTerm) {
      return of(null);
    }
    const url = `${this.apiBaseUrl}api/v1/patients/search/term?term=${encodeURIComponent(searchTerm)}`;
    return this._dataService.get(url);
  }
}
