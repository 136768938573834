import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: 'input[ssn]',
})
export class SsnDirective implements AfterViewInit, OnChanges {
  @Input() ssn: boolean;
  @Input() mask: string = '000-00-0000';
  @Input() concealCharacter: string = '#';

  private _originalValue: string;
  private _hasFocus: boolean = false;

  private _removeFocusListener: () => void | undefined;
  private _removeFocusLostListener: () => void | undefined;

  constructor(
    private readonly _el: ElementRef,
    private readonly _renderer: Renderer2,
  ) {
  }

  ngAfterViewInit(): void {
    if (this.ssn) {
      setTimeout(() => this.reset());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('ssn' in changes) {
      this.reset();
    }
  }

  @HostListener('ngModelChange', ['$event'])
  onNgModelChange() {
    if (!this._hasFocus) {
      this.onFocusLost();
    }
  }

  private onFocus = (): void => {
    const originalValue = this._originalValue;
    if (originalValue != null) {
      this.displayValue(originalValue);
    }

    this._hasFocus = true;
  }

  private onFocusLost = (): void => {
    this._hasFocus = false;

    this._originalValue = this.getValue();
    this.displayMaskedValue();
  }

  private getValue = (): string => this._el.nativeElement.value;

  private displayValue = (value: string) => this._el.nativeElement.value = value;

  private displayOriginalValue = () => this.displayValue(this._originalValue);

  private getMaskedValue = () => {
    const {
      mask,
      concealCharacter,
    } = this;

    const regex = /[-()]/g;

    return Array.from(
      mask,
      (c) => regex.test(c) ? c : concealCharacter,
    ).join('');
  };

  private displayMaskedValue = () => this.displayValue(this.getMaskedValue());

  private reset(): void {
    const {
      _renderer: renderer,
      _el: { nativeElement },
    } = this;
    this._removeFocusListener?.();
    this._removeFocusLostListener?.();

    if (!this.ssn) {
      this.displayOriginalValue();
      return;
    }

    this._removeFocusListener = renderer.listen(
      nativeElement,
      'focus',
      this.onFocus,
    );
    this._removeFocusLostListener = renderer.listen(
      nativeElement,
      'focusout',
      this.onFocusLost,
    );

    // It is assumed that the current state is unfocused.
    this.onFocusLost();
  }
}
