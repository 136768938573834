import { EnumerationItem } from "../models/lookup.model";
import { DateTime } from "luxon";

export class Utils {
  static parseJwt(token: string): any {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  static getTokenExpireDate(exp: number) {
    let expiresDate = new Date(0);
    expiresDate.setUTCMilliseconds(exp * 1000);

    return expiresDate;
  }

  static splitCamelCase(value: string | undefined): string {
    return value?.replace(/([a-z])([A-Z])/g, '$1 $2') ?? '';
  }

  static toTitleCase(value: string): string {
    return value.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }

  static formatISODateWithoutTime(date: Date) {
    let month: number | string = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    let day: number | string = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    return date.getFullYear() + "-" + month + "-" + day + "T00:00:00";
  }

  static getStartOfDayISODate(date: Date): string {
    if (!date) {
      return '';
    }
    const dateString = new Date(date.setHours(0, 0, 0, 0)).toISOString()
    return dateString;
  }

  static getEndOfDayISODate(date: Date): string {
    if (!date) {
      return '';
    }
    const dateString = new Date(date.setHours(23, 59, 59, 59)).toISOString()
    return dateString;
  }

  static getStartOfWeek() {
    return DateTime.now().startOf('week').startOf('day').toJSDate();
  }

  static getEndOfWeek() {
    return DateTime.now().endOf('week').endOf('day').toJSDate();
  }
  static getStartOfMonth() {
    return DateTime.now().startOf('month').startOf('day').toJSDate();
  }

  static getEndOfMonth() {
    return DateTime.now().endOf('month').endOf('day').toJSDate();
  }

  static MinsToHm = (minsFromMidNight: number): string => {
    minsFromMidNight = Number(minsFromMidNight);
    const h = Math.floor(minsFromMidNight / 60);
    const m = minsFromMidNight % 60;
    const formattedHours = h.toString().length === 1
      ? '0' + h.toString()
      : h.toString();
    const formattedMins = m.toString().length === 1
      ? '0' + m.toString()
      : m.toString();
    return `${formattedHours}:${formattedMins}`;
  }

  static HmToMins = (formattedTime: string): number => {
    const hmString = formattedTime.split(':');
    if (hmString.length !== 2) {
      return 0;
    }
    const hrs = Number(hmString[0]);
    const mins = Number(hmString[1]);
    return (hrs * 60) + mins;
  }

  static getTimeInMinutesFromMidnight(date: Date): number {
    if (!(date instanceof Date)) {
      return 0;
    }

    const hours = date.getHours();
    const minutes = date.getMinutes();

    return hours * 60 + minutes;
  }

  static formatPhoneNumber = (number: string): string => {
    let cleaned = ('' + number).replace(/\D/g, ''),
      match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{3}|\d{4})$/);
    if (match) {
      let intlCode = (match[1] ? '+1 ' : '')
      return ['(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return number;
  }

  static getEnumerationItemsFromDayOfWeekEnum() {
    return Object.keys(DayOfWeek)
      .map(key => ({ value: DayOfWeek[key], displayName: key }))
  }

  static getEnumerationItemsFromMaritalStatusEnum() {
    return Object.keys(MaritalStatus)
      .map(key => ({ value: MaritalStatus[key], displayName: key }))
  }

  static getEnumerationItemsFromGenderEnum() {
    return Object.keys(Gender)
      .map(key => ({ value: Gender[key], displayName: key }))
  }

  static getEnumerationItemsFromEthnicityEnum() {
    return Object.keys(Ethnicity)
      .map(key => ({ value: Ethnicity[key], displayName: key }))
  }

  static getEnumerationItemsFromRelationshipEnum() {
    return Object.keys(Relationship)
      .map(key => ({ value: key, displayName: Relationship[key] }))
  }

  static getEnumerationItemsFromMedicationStatusEnum() {
    return Object.keys(MedicationStatus)
      .map(key => ({ value: MedicationStatus[key], displayName: key }))
  }

  static getEnumerationItemsFromCaffeineTypeEnum() {
    return Object.keys(CaffeineType)
      .map(key => ({ value: CaffeineType[key], displayName: key }))
  }

  static getEnumerationItemsFromSmokingTypeEnum() {
    return Object.keys(SmokingType)
      .map(key => ({ value: SmokingType[key], displayName: key }))
  }

  static getImplantTypesWithAccessSites(): EnumerationItem[] {
    return [
      {
        value: 'arterial',
        displayName: 'Arterial',
        children: [{
          value: 'Left_Femoral_Artery',
          displayName: 'Left Femoral Artery'
        }, {
          value: 'Left_Popliteal_Artery',
          displayName: 'Left Popliteal Artery'
        }, {
          value: 'Left_Radial_Artery',
          displayName: 'Left Radial Artery'
        }, {
          value: 'Left_Tibial_Artery',
          displayName: 'Left Tibial Artery'
        }, {
          value: 'Right_Femoral_Artery',
          displayName: 'Right Femoral Artery'
        }, {
          value: 'Right_Popliteal_Artery',
          displayName: 'Right Popliteal Artery'
        }, {
          value: 'Right_Radial_Artery',
          displayName: 'Right Radial Artery'
        }, {
          value: 'Right_Tibial_Artery',
          displayName: 'Right Tibial Artery'
        }]
      }, {
        value: 'venous',
        displayName: 'Venous',
        children: [{
          value: 'Left_Femoral_Vein',
          displayName: 'Left Femoral Vein'
        }, {
          value: 'Left_Popliteal_Vein',
          displayName: 'Left Popliteal Vein'
        }, {
          value: 'Right_Femoral_Vein',
          displayName: 'Right Femoral Vein'
        }, {
          value: 'Right_Popliteal_Vein',
          displayName: 'Right Popliteal Vein'
        }]
      }, {
        value: 'carotid',
        displayName: 'Carotid',
        children: [{
          value: 'Left_Femoral_Artery',
          displayName: 'Left Femoral Artery'
        }, {
          value: 'Left_Popliteal_Artery',
          displayName: 'Left Popliteal Artery'
        }, {
          value: 'Left_Radial_Artery',
          displayName: 'Left Radial Artery'
        }, {
          value: 'Left_Tibial_Artery',
          displayName: 'Left Tibial Artery'
        }, {
          value: 'Right_Femoral_Artery',
          displayName: 'Right Femoral Artery'
        }, {
          value: 'Right_Popliteal_Artery',
          displayName: 'Right Popliteal Artery'
        }, {
          value: 'Right_Radial_Artery',
          displayName: 'Right Radial Artery'
        }, {
          value: 'Right_Tibial_Artery',
          displayName: 'Right Tibial Artery'
        }]
      }
    ];
  }

  static getProcedureDrugs(): EnumerationItem[] {
    return [{
      displayName: 'Xarelto',
      value: 'DBPC0250169'
    },
    {
      displayName: 'Isovue 250',
      value: 'DBPC0361016'
    },
    {
      displayName: 'Heparin',
      value: 'DBPC0021660'
    },
    {
      displayName: 'Fentanyl',
      value: 'DBPC0011203'
    },
    {
      displayName: 'Midazolam',
      value: 'DBPC0049584'
    },
    {
      displayName: 'Alprazolam [Xanax]',
      value: 'DBPC0248883'
    },
    ]
  }

  static getSupplements(): EnumerationItem[] {
    return [{
      displayName: 'Biotin',
      value: 'Biotin'
    }, {
      displayName: 'Calcium',
      value: 'Calcium'
    }, {
      displayName: 'Creatine',
      value: 'Creatine'
    }, {
      displayName: 'Fish oil',
      value: 'Fish oil'
    }, {
      displayName: 'Folate',
      value: 'Folate'
    }, {
      displayName: 'Glutamine',
      value: 'Glutamine'

    }, {
      displayName: 'Iodine',
      value: 'Iodine'
    }, {
      displayName: 'Iron',
      value: 'Iron'
    }, {
      displayName: 'Magnesium',
      value: 'Magnesium'

    }, {
      displayName: 'Mineral',
      value: 'Mineral'

    }, {
      displayName: 'Niacin',
      value: 'Niacin'

    }, {
      displayName: 'Pantothenic acid',
      value: 'Pantothenic acid'

    }, {
      displayName: 'Probiotics',
      value: 'Probiotics'

    }, {
      displayName: 'Protein',
      value: 'Protein'

    }, {
      displayName: 'Riboflavin',
      value: 'Riboflavin'

    }, {
      displayName: 'Thiamin',
      value: 'Thiamin'

    }, {
      displayName: 'Vitamin A',
      value: 'Vitamin A'

    }, {
      displayName: 'Vitamin B12',
      value: 'Vitamin B12'

    }, {
      displayName: 'Vitamin B6',
      value: 'Vitamin B6'

    }, {
      displayName: 'Vitamin C',
      value: 'Vitamin C'

    }, {
      displayName: 'Vitamin D',
      value: 'Vitamin D'

    }, {
      displayName: 'Vitamin E',
      value: 'Vitamin E'
    }, {
      displayName: 'Vitamin K',
      value: 'Vitamin K'
    }, {
      displayName: 'Zinc',
      value: 'Zinc'
    }];
  }

  static getLanguages(): EnumerationItem[] {
    return [
      { displayName: 'English', value: 'English' },
      { displayName: 'Spanish', value: 'Spanish' },
      { displayName: 'Arabic', value: 'Arabic' },
      { displayName: 'French', value: 'French' },
      { displayName: 'German', value: 'German' },
      { displayName: 'Korean', value: 'Korean' },
      { displayName: 'Vietnamese', value: 'Vietnamese' },
      { displayName: 'Russian', value: 'Russian' },
      { displayName: 'Chinese', value: 'Chinese' },
      { displayName: 'Tagalog', value: 'Tagalog' },
      { displayName: 'Portuguese', value: 'Portuguese' },
      { displayName: 'Italian', value: 'Italian' },
      { displayName: 'Cantonese', value: 'Cantonese' },
      { displayName: 'Polish', value: 'Polish' }
    ];
  }

  static getModalityList(): EnumerationItem[] {
    return [
      { displayName: 'XA', value: 'XA' },
      { displayName: 'IVUS', value: 'IVUS' }
    ];
  }
}

export enum DayOfWeek {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday"
}

export enum MaritalStatus {
  Single = 'Single',
  Married = 'Married',
  Divorced = 'Divorced',
  Widowed = 'Widowed',
  Separated = 'Separated'
}

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export enum Ethnicity {
  AmericanIndianOrAlaskaNative = 'American Indian or Alaska Native',
  Asian = 'Asian',
  BlackOrAfricanAmerican = 'Black or African American',
  White = 'White',
  Unknown = 'Unknown'
}

export enum Relationship {
  GrandFather = "Grand Father",
  GrandMother = "Grand Mother",
  Father = "Father",
  Mother = "Mother",
  Son = "Son",
  Daughter = "Daughter",
  Spouse = "Spouse",
  Sibling = "Sibling",
  Uncle = "Uncle",
  Aunt = "Aunt"
}

export enum PatientStatus {
  Admitted = 'admitted'
}

export enum MedicationStatus {
  Active = 'active',
  InActive = 'inactive'
}

export enum StressSource {
  WorkRelated = 'WorkRelated',
  FamilyIssues = 'FamilyIssues',
  RecentLifeEvents = 'RecentLifeEvents'
}

export enum UserAnswer {
  Yes = 'Yes',
  No = 'No',
  Unknown = 'Unknown'
}

export enum Routine {
  Daily = 'Daily',
  Sometimes = 'Sometimes',
  MultipleTimes = "MultipleTimes",
  TwiceAWeek = 'TwiceAWeek',
  Socially = 'Socially'
}

export enum CaffeineType {
  Coffee = 'Coffee',
  Soda = 'Soda',
  EneryDrinks = 'EneryDrinks',
  Supplements = 'Supplements'
}

export enum SmokingType {
  Cigars = 'Cigars',
  Cigarettes = 'Cigarettes',
  Chew = 'Chew',
  Pipe = 'Pipe',
  Marijuana = 'Marijuana'
}