import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'Dashboard',
    //     iconUrl: 'assets/images/custom/sidebar/dashboard.svg',
    //     link: '/',
    // },
    {
        id: 2,
        label: 'Patients',
        iconUrl: 'assets/images/custom/sidebar/patients.svg',
        activeIconUrl: 'assets/images/custom/sidebar/patients-active.svg',
        link: '/patients',
        width: 18
    }
];

