<file-upload [control]="control" [class]="customClass" class="p-0 text-center">
    <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
        <div *ngIf="control.size === 0" class="d-flex flex-column justify-content-between align-items-center">
            <i class="fa fa-image-alt fs-1"></i>
            <ng-container *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable">
                <span class="text-center mt-1"><b>Drag and drop</b> files or click here</span>
            </ng-container>
            <ng-template #isNotDragDropAvailable>
                <span class="text-center mt-1"><b>Click here</b> to choose a files</span>
            </ng-template>
        </div>
    </ng-template>

    <ng-template let-i="index" let-file="file" let-control="control" #item>
        <div class="overlay">
            <span class="delete-button" (click)="control.removeFile(file)">
                <i class="fa fa-trash remove-image-icon"></i>
            </span>
            <img class="image image-contain" *ngIf="control.valid" [src]="uploadedFile | async">
        </div>
    </ng-template>
</file-upload>