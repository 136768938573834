import { Injectable } from '@angular/core';
import { ApplicationContext } from '../models/application-context.model';
import { Observable, Subject, catchError, map, of, switchMap } from 'rxjs';
import { AuthenticationService, CompanyProductLicense, User, UserCompany } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationContextService {
  private _applicationContext: ApplicationContext;
  private _contextChangedSubject: Subject<ApplicationContext> = new Subject<ApplicationContext>();

  constructor(private readonly _authService: AuthenticationService) { }

  get context(): Observable<ApplicationContext> {
    if (this._applicationContext) {
      return of(this._applicationContext);
    }
    return this.initializeApplicationContext().pipe(map(context => {
      this._applicationContext = context;
      this._contextChangedSubject.next(context);
      return context;
    }));
  }

  get contextChanged$(): Subject<ApplicationContext> {
    return this._contextChangedSubject;
  }

  resetContext() {
    this._applicationContext = null;
  }


  private initializeApplicationContext = (): Observable<ApplicationContext> => {
    return this._authService.getLoggedInUser().pipe(
      catchError((error) => of(error)),
      switchMap((user: User) => this._authService.getUserCompanies(user.id).pipe(
        catchError((error) => of(error)),
        switchMap((userCompanies: UserCompany[]) => this._authService.getUserLicenses().pipe(
          map((licenses: CompanyProductLicense[]) => ({
            currentlyLoggedInUser: user,
            userCompanies,
            companyLicenses: licenses.map(l => l.name),
            hasNoBillingPhysiciansForCharts: licenses.some(l => l.name === 'charts' && l.activeBillingPhysicians === 0)
          })
          )))
      )
      ));
  }
}

