import { Pipe } from "@angular/core";
import { Utils } from "src/app/core/services/utils";

@Pipe({
  name: "phone"
})
export class PhonePipe {

  transform(number: string) {
    return Utils.formatPhoneNumber(number);
  }
}
